<template>
    <section>
      <va-breadcrumbs>
        <va-breadcrumbs-item label="Educação" to="/admin/educacao/escolas" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Planejamento Pedagógico Professores</span
          >
        </va-breadcrumbs-item>
      </va-breadcrumbs>

      <h2 class="titulo-links-escolha">Planejamento Pedagógico Professores</h2>
      <p>
        A tabela a seguir mostra os Planejamentos Pedagógicos que os Professores escrevem para<br>
        cada disciplina em um ensino escolar.
      </p>
      <div class="form-group col-md-12" v-if="escola == null">
        <label style="margin-bottom: 21px;color: #c9c9c9;">Escolha uma Escola:</label>
        <div class="row">
            <div class="col-md-3 col-6" 
                style="cursor:pointer;margin-bottom: 12px;"  v-for="n in escolas" :key="n" :value="n.id" @click="buscarTurma(n.id,n.nome)">
                <va-card>
                  <div class="flex md12" style="padding:0px;padding-bottom:10px;">
                    <i class="fas fa-book-reader" style="font-size: 48px;text-align: center;width: 100%;color: #4387dd;"  ></i>
                  </div> 
                  <div class="flex md12" style="text-align: center; padding:0px;color: #4387dd;padding-bottom:10px">
                    <p style="margin: 0;color: #626262;"><b>Escola</b></p>
                    <p class="escolha-nome-disciplina">
                      {{n.nome}}
                    </p>
                  </div>
                </va-card> 
            </div> 
        </div>
      </div>

      <div class="form-group col-md-12" v-if="escola != null">
            <label style="margin-top: 29px;font-size: 18px;">Escola <b>{{escola_nome}}</b></label>
            
            <button  style="margin-left:10px;" type="button" class="btn btn-primary" @click="escola = null, turma = null, turmas = [], disciplina = [] "> Trocar escola</button>
            <br>
            <label style="margin-bottom: 21px;color: #c9c9c9;">Escolha uma Turma:</label>
            <div class="row">
              <div class="col-md-2 col-6" 
                  style="cursor:pointer;margin-bottom: 12px;"  v-for="n in turmas" :key="n" :value="n" @click="turma_disciplinas(n)" >
                <va-card >
                  <div class="flex md12" style="padding:0px;padding-bottom:10px;">
                    <i class="fas fa-book-reader" style="font-size: 48px;text-align: center;width: 100%;color: #4387dd;"  ></i>
                  </div> 
                  <div class="flex md12" style="text-align: center; padding:0px;color: #4387dd;padding-bottom:10px">
                    <p style="padding-top: 6px;">{{n.segmento}} - {{n.serie}}</p>
                    <p style="margin: 0;color: #626262;"><b>Turma</b></p>
                    <p class="escolha-nome-disciplina">
                      {{n.nome}}
                    </p>
                    <p style="margin: 0;color: #626262;">{{n.turno}}</p>
                  </div>
                </va-card> 
              </div> 
              
            </div>
       </div>

       <div class="row" v-if="disciplina.length">
        <h4 style="margin-top:15px;font-weight:bold;font-size:15px;margin-bottom: 20px;">
          Escolha a Disciplina.
        </h4>
        <div class="col-md-12">
          <div class="row" style="margin-bottom:15px;margin-top:15px;" >
            <div class="col-md-2 col-6" style="cursor:pointer;margin-bottom: 12px;" v-for="n in disciplina" :key="n" >
              <va-card style="padding:3px;">
                <div class="flex md12" style="padding:0px;padding-bottom:10px;">
                  <i class="fas fa-book-reader" style="font-size: 48px;text-align: center;width: 100%;color: #4387dd;"  ></i>
                </div> 
                <div class="flex md12" style="text-align: center; padding:0px;color: #4387dd;" @click="buscarplanejamentoProf(n)">
                  <p style="margin: 0;color: #626262;"><b>Disciplina</b></p>
                  <p class="escolha-nome-disciplina">
                    {{n.nome}}
                  </p>
                </div>
              </va-card> 
            </div> 
          </div>            
        </div>
      </div>

      <br>
      <va-input
        v-if="items.length > 5"
        class="flex mb-2 md6"
        placeholder="Filtrar..."
        v-model="filter"
      />
      <va-data-table  striped="true" hoverable="true" animated="true" 
        
        :items="items" 
        :columns="columns"
        :per-page="perPage"
        :filter="filter"
        :current-page="currentPage" > 

     

        <template #bodyAppend>
          <tr><td colspan="8" class="table-example--pagination">
            <va-pagination
              v-model="currentPage"
              input
              :pages="pages"
            />
          </td></tr>
        </template>
      </va-data-table> 
    </section>  
</template>

<script>
import { defineComponent } from 'vue'
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import { DisciplinaEscolar } from "@/class/disciplina";
import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma";
import { Calendario } from "@/class/calendario";

export default defineComponent({
  data () {
    const columns = [
      {key: 'turma', label:'Turma', sortable: true },
      {key: 'professor', label:'Professor', sortable: true },
      {key: 'disciplina', label:'Disciplina', sortable: true },
      {key: 'date', label:'Data', sortable: true },
      {key: 'texto', label:'Conteúdo'},
    ]
    return {
      situacaoAno:1,
      filter: '',
      escola_nome:'',
      items: [],
      escola:null,
      escolas:[],
      turma:null,
      professor:0,
      turmas:[],
      disciplina_id:0,
      disciplina: [],
      columns,
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.items.length / this.perPage)
          : this.items.length
    },
  },
  methods:{
      registar(){
        this.$router.push({ name: 'planejamentoPedaCad'});
      },
      editarPlanejamento(id) {
        this.$router.push({ name: "planejamentoPedaEdit", params: { id: id } });
      },
      async deletarPlanejamento(id) {
       const data = await PlanejamentoPedagogico.remover(id);
       this.listarTudo();

      },
      async listarEscolas(){
        const esc = await Escola.obtemTodos();
        this.escolas = esc.data;
      },
      async buscarTurma(id, nome) {
        try{
            this.escola = id;
            this.escola_nome = nome;
            let anoSelect = sessionStorage.getItem("anoSelecionado");
            const data = await Turma.obtemPorEscolaAno(id,anoSelect);
            this.turmas = data.data;
            
            this.items = [];
            this.buscarplanejamentoProf(null);
        }catch(e){

        }
      },
      async turma_disciplinas(turma) {
        this.turma = turma.id;
       
        let data1 = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma);
        this.disciplina = [];
        for (const el of data1.data) {
            if(el.na_turma == 1){
                let novo = {
                    id: el.id,
                    nome: el.nome,
                    status: el.status
                }
                this.disciplina.push(novo);
            }
        }
        this.items = [];
        this.buscarplanejamentoProf(null);
      },
      async buscarplanejamentoProf(n){
        try{
          this.items = [];
          let calendario = await Calendario.calendarioAtual();
          let turma_id = 0;
          if(this.turma == null){
            turma_id = 0;
          }else{
            turma_id = this.turma; 
          }

          if(n != null){
            this.disciplina_id = n.id;
          }

          let playload = {
            ano: calendario.data.ano,
            professor_id: this.professor,
            disciplina_id: this.disciplina_id,
            turma_id: turma_id,
          };

          let data = await PlanejamentoPedagogico.obtemPlanejamentoProf(playload);

          for (const el of data.data) {
            let nv = {
              date: el.dia+'/'+el.mes+'/'+el.ano,
              disciplina: el.disciplina,
              id: el.id,
              professor: el.professor,
              texto: el.texto,
              turma: el.turma,
            }
            this.items.push(nv);
          }
        }catch (e) { 
        }
      },
      async listarTudo() {
        try {
            this.items = [];
            let anoSelect = sessionStorage.getItem("anoSelecionado");
            let data = await PlanejamentoPedagogico.buscarPlanejamento(anoSelect);

            //this.items = data.data;
            for (const el of data.data) {
              let novo = {
                id_: el.id,
                id: el.id,
                segmento_id:el.segmento_id,
                segmento: el.segmento,
                serie_id: el.serie_id,
                serie: el.serie,
                disciplina_id: el.disciplina_id,
                disciplina: el.disciplina,
                ano: el.ano,
                mes: el.mes,
                dia: el.dia,
                conteudo: el.conteudo
              };
              this.items.push(novo);
            };
        } catch (e) { 
        }
      }, 
  },

  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.listarTudo();
    await this.listarEscolas();
  }
  
})

</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }


  
</style>

